import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import Constants from 'expo-constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Checkbox from '../../components/Checkbox';
import DateInput from '../../components/DateInput';
import Input from '../../components/Input';
import Select from '../../components/Select';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { V4Signature } from '../../types/contracts/signature.model';
import { V4ContractTemplate } from '../../types/contracts/template.model';
import {
  CONTRACTS_CREATE_DIMONA_USER_TYPES,
  CONTRACTS_REGIME_TYPES,
  CONTRACTS_RENEW_OPTIONS,
  CONTRACTS_RENEW_PERIODS,
  CONTRACTS_SALARY_PERIODS,
} from '../../utils';

const V4_API_URL = (
  (Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4) as string
).replace('/staff', '');

const CreateNewContractScreen: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { handleSubmit, errors, control, watch, setValue, getValues } = useForm();
  const watch_department = watch('departmentId', null);
  const watch_user = watch('userRecordId', null);
  const watch_template = watch('templateId', null);
  const watch_renew = watch('renew', null);
  const watch_renewPeriod = watch('renewPeriod', null);
  const watch_dimona = watch('dimona', null);
  const watch_dimona_user_type = watch('dimonaWorkerType', null);
  const { access_documents_departments } = useSelector((store: InitialState) => store.data);

  const [loading, setLoading] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<V4Signature[]>([]);

  const [templates, setTemplates] = useState<V4ContractTemplate[]>([]);

  const activeDepartment =
    access_documents_departments &&
    access_documents_departments.find((department) => department.id === watch_department);
  const active_user = activeDepartment?.users?.find((user) => user.recordId === watch_user);

  useEffect(() => {
    if (access_documents_departments && access_documents_departments.length == 1) {
      setValue('departmentId', access_documents_departments[0].id);
    }
  }, []);

  useEffect(() => {
    if (watch_user) {
      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/users/${watch_user}/params`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          const { params } = data;
          const { automatic_contract_template_id, dimona, automatic_contracts, workerType, hr_hours_week } = params;

          if (automatic_contract_template_id) {
            const template = templates.find((template) => template.id == automatic_contract_template_id);

            if (template) {
              if (template.type == 'upload') {
                setValue('file', template.documentFileId);
              } else {
                setValue('html', template.html);
              }
              setValue('templateId', template.id);
              setValue('type', template.type);
              setValue('signatureId', template.fields.signatureId);
              setValue('daysOffYear', template.fields.daysOffYear);
              setValue('daysWeek', template.fields.daysWeek);
              setValue('hoursWeek', template.fields.hoursWeek);
              setValue('maxHoursDay', template.fields.maxHoursDay);
              setValue('salaryAmountGross', template.fields.salaryAmountGross);
              setValue('salaryPeriod', template.fields.salaryPeriod);
            }
          }

          setValue('renew', automatic_contracts ? automatic_contracts : null);
          setValue('dimona', dimona ? dimona : null);
          setValue(
            'dimonaWorkerType',
            workerType ? CONTRACTS_CREATE_DIMONA_USER_TYPES.find((user_type) => user_type.id === workerType)?.id : null,
          );
          setValue('dimonaPlannedHours', hr_hours_week ? hr_hours_week.toString() : null);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `${
            Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
          }/v3/users/${watch_user}/hr/active`,
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          const { price, wage_type, hr_max_hours_week, hr_days_week, legal_leaves } = data.data;

          setValue('hoursWeek', hr_max_hours_week ? hr_max_hours_week.toString() : null);
          setValue('salaryAmountGross', price ? price.toString() : null);
          setValue('salaryPeriod', wage_type ? wage_type.toString() : null);
          setValue('daysWeek', hr_days_week ? hr_days_week.toString() : null);
          setValue('daysOffYear', legal_leaves ? legal_leaves.toString() : null);
          setValue(
            'maxHoursDay',
            hr_max_hours_week && hr_days_week ? Math.floor(hr_max_hours_week / hr_days_week).toString() : null,
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [watch_user, templates]);

  useEffect(() => {
    if (!activeDepartment) return;

    getTemplates();
    getSignatures();
  }, [activeDepartment]);

  useEffect(() => {
    const templateId = getValues('templateId');

    if (!templateId) return;

    const template = templates.find((template) => template.id == templateId);

    if (!template) return;

    setValue('type', template.type);
    if (template.type == 'upload') {
      setValue('file', template.documentFileId);
    } else {
      setValue('html', template.html);
    }
    setValue('signatureId', template.fields.signatureId);
  }, [watch_user, watch_template, templates]);

  const onSubmit = (values: any) => {
    if (!activeDepartment) return;

    axios
      .post(
        `${V4_API_URL}/documents/contracts`,
        {
          ...values,
          start: moment(values.start).format('YYYY-MM-DD'),
          end: moment(values.end).format('YYYY-MM-DD'),
          file: values.file ? values.file : undefined,
          html: values.html ? values.html : undefined,
          renewal: values.renew ? { period: values.renewPeriod, option: values.renewOption } : undefined,
        },
        {
          params: {
            departmentId: activeDepartment.id,
          },
        },
      )
      .then(({ data }) => {
        navigation.navigate('Home');
        showMessage({
          message: t('CONTRACTS.FLASH_MESSAGES.CREATED', { name: active_user?.displayName }),
          type: 'success',
        });
        setLoading(false);
      })
      .catch((err) => {
        showMessage({
          message: t('CONTRACTS.FLASH_MESSAGES.ERROR'),
          type: 'danger',
        });
        setLoading(false);
      });
  };

  const getTemplates = () => {
    if (!activeDepartment) return;

    axios
      .get(`${V4_API_URL}/documents/templates/contract`, {
        params: {
          departmentId: activeDepartment.id,
        },
      })
      .then(({ data }) => {
        setTemplates(data.data);
      })
      .catch(() => {});
  };

  const getSignatures = () => {
    if (!activeDepartment) return;

    axios
      .get(`${V4_API_URL}/documents/signatures`, {
        params: {
          departmentId: activeDepartment.id,
        },
      })
      .then(({ data }) => {
        setSignatures(data.data);
        const defaultSignature = data.data.find((signature: V4Signature) => signature.default);
        if (defaultSignature) {
          setValue('signatureId', defaultSignature.id);
        } else {
          setValue('signatureId', null);
        }
      })
      .catch((err) => {});
  };

  return (
    <SafeAreaView style={{ backgroundColor: colors.blueExtraLight, flex: 1 }}>
      <Back title={t('CONTRACTS.CREATE_CONTRACT')} />
      <ScrollView style={styles.container}>
        <View style={styles.form}>
          <SIText style={styles.title}>{t('CONTRACTS.GENERAL_INFORMATION')}</SIText>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={
              access_documents_departments &&
              access_documents_departments.map((department) => ({
                label: department.company!,
                value: department.id!,
              }))
            }
          />
          <Select
            placeholder={t('GENERAL.USER')}
            label={t('GENERAL.USER')}
            control={control}
            defaultValue={null}
            name="userRecordId"
            items={activeDepartment?.users?.map((user) => ({ label: user.displayName!, value: user.recordId! })) || []}
            rules={{ required: true }}
            errors={errors}
          />
          <Select
            placeholder={t('CONTRACTS.TEMPLATE')}
            label={t('CONTRACTS.TEMPLATE')}
            control={control}
            defaultValue={null}
            name="templateId"
            items={templates.map((template) => ({ label: template.name, value: template.id })) || []}
            rules={{ required: true }}
            errors={errors}
          />
          <Input
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            name="type"
            style={{ display: 'none' }}
          />
          <Input defaultValue={null} control={control} name="html" style={{ display: 'none' }} />
          <Input defaultValue={null} control={control} name="file" style={{ display: 'none' }} />
          <View>
            <DateInput
              control={control}
              name="start"
              label={t('DIMONA.START_PERIOD')}
              rules={{ required: true }}
              mode="date"
              watch={watch}
              errors={errors}
            />
            <DateInput
              control={control}
              name="end"
              label={t('DIMONA.END_PERIOD')}
              rules={{ required: true }}
              mode="date"
              watch={watch}
              errors={errors}
            />
            <Select
              placeholder={t('CONTRACTS.SIGNATORY')}
              label={t('CONTRACTS.SIGNATORY')}
              control={control}
              defaultValue={null}
              name="signatureId"
              items={
                signatures.map((signature) => ({
                  label: `${signature.name} - ${signature.title}`,
                  value: signature.id,
                })) || []
              }
              rules={{ required: true }}
              errors={errors}
            />
          </View>
          <SIText style={styles.title}>{t('CONTRACTS.ADDITIONAL_INFORMATION')}</SIText>
          <Input
            defaultValue={null}
            label={t('CONTRACTS.WEEK_HOURS')}
            placeholder={t('CONTRACTS.WEEK_HOURS')}
            rules={{ required: true }}
            control={control}
            name="hoursWeek"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.SALARY')}
            placeholder={t('CONTRACTS.SALARY')}
            rules={{ required: true }}
            control={control}
            name="salaryAmountGross"
            keyboardType="numeric"
            errors={errors}
          />
          <Select
            placeholder={t('CONTRACTS.SALARY_PERIOD')}
            label={t('CONTRACTS.SALARY_PERIOD')}
            control={control}
            defaultValue={null}
            name="salaryPeriod"
            items={CONTRACTS_SALARY_PERIODS.map((period, i) => ({
              label: t(`CONTRACTS.SALARY_PERIODS.${i}`),
              value: period,
            }))}
            rules={{ required: true }}
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.WEEK_DAYS')}
            placeholder={t('CONTRACTS.WEEK_DAYS')}
            control={control}
            name="daysWeek"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.YEAR_DAYOFFS')}
            placeholder={t('CONTRACTS.YEAR_DAYOFFS')}
            control={control}
            name="daysOffYear"
            keyboardType="numeric"
            errors={errors}
          />
          <Input
            defaultValue={null}
            label={t('CONTRACTS.MAX_DAILY_HOURS')}
            placeholder={t('CONTRACTS.MAX_DAILY_HOURS')}
            control={control}
            name="maxHoursDay"
            keyboardType="numeric"
            errors={errors}
          />
          <Select
            placeholder={t('CONTRACTS.REGIME_TYPE')}
            label={t('CONTRACTS.REGIME_TYPE')}
            control={control}
            defaultValue={null}
            name="workingTimePlan"
            items={CONTRACTS_REGIME_TYPES.map((regime) => ({
              label: t(`CONTRACTS.REGIME_TYPES.${regime}`),
              value: regime,
            }))}
            errors={errors}
          />
          <Checkbox
            control={control}
            label={t('CONTRACTS.AUTO_RENEW.TITLE')}
            name="renew"
            style={{ marginBottom: 15 }}
          />
          {watch_renew && (
            <View>
              <Select
                placeholder={t('CONTRACTS.AUTO_RENEW.RENEW_RECURRENCE')}
                label={t('CONTRACTS.AUTO_RENEW.RENEW_RECURRENCE')}
                control={control}
                defaultValue={null}
                name="renewPeriod"
                items={CONTRACTS_RENEW_PERIODS.map((period) => ({
                  label: t(`CONTRACTS.AUTO_RENEW.RENEW_PERIODS.${period}`),
                  value: period,
                }))}
                rules={{ required: true }}
                errors={errors}
              />
              {['weekly', 'monthly', 'quarterly', 'yearly'].includes(watch_renewPeriod) && (
                <Select
                  placeholder={t('CONTRACTS.AUTO_RENEW.RENEW_PERIOD')}
                  label={t('CONTRACTS.AUTO_RENEW.RENEW_PERIOD')}
                  control={control}
                  defaultValue={null}
                  name="renewOption"
                  items={CONTRACTS_RENEW_OPTIONS.filter((period) => {
                    if (period == 'same_dates') return true;
                    if (watch_renewPeriod == 'weekly' && period == 'full_week') return true;
                    if (watch_renewPeriod == 'monthly' && period == 'full_month') return true;
                    if (watch_renewPeriod == 'quarterly' && period == 'full_quarter') return true;
                    if (watch_renewPeriod == 'yearly' && period == 'full_year') return true;
                    return false;
                  }).map((period) => ({
                    label: t(`CONTRACTS.AUTO_RENEW.RENEW_PERIODS.${period}`),
                    value: period,
                  }))}
                  rules={{ required: true }}
                  errors={errors}
                />
              )}
            </View>
          )}
          {activeDepartment?.modules?.dimona && (
            <View>
              <Checkbox
                control={control}
                label={t('CONTRACTS.CREATE_DIMONA.TITLE')}
                name="dimona"
                style={{ marginBottom: 15 }}
              />
              {watch_dimona && (
                <View>
                  <Select
                    placeholder={t('CONTRACTS.CREATE_DIMONA.WORKER_TYPE')}
                    label={t('CONTRACTS.CREATE_DIMONA.WORKER_TYPE')}
                    control={control}
                    defaultValue={null}
                    name="dimonaWorkerType"
                    items={CONTRACTS_CREATE_DIMONA_USER_TYPES.map((user_type) => ({
                      label: user_type.name,
                      value: user_type.id,
                    }))}
                    rules={{ required: true }}
                    errors={errors}
                  />
                  {watch_dimona_user_type == 'STU' && (
                    <Input
                      defaultValue={null}
                      label={t('CONTRACTS.CREATE_DIMONA.HOURS_NUMBER')}
                      placeholder={t('CONTRACTS.CREATE_DIMONA.HOURS_NUMBER')}
                      rules={{ required: true }}
                      control={control}
                      name="dimonaPlannedHours"
                      keyboardType="numeric"
                      errors={errors}
                    />
                  )}
                </View>
              )}
            </View>
          )}
          <SIButton title={t('GENERAL.CONFIRM')} size="large" loading={loading} onPress={handleSubmit(onSubmit)} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: colors.blueExtraLight,
    minHeight: '100%',
  },
  form: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 25,
    marginBottom: Platform.OS != 'web' ? 150 : 50,
  },
  title: {
    marginBottom: 10,
    color: colors.green,
    fontWeight: 'bold',
    fontSize: 18,
  },
});

export default CreateNewContractScreen;
