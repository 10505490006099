import AsyncStorage from '@react-native-async-storage/async-storage';
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  DrawerActions,
  NavigationContainer,
  NavigationContainerRef,
  RouteProp,
  useNavigation,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { default as Axios, default as axios } from 'axios';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, AppStateStatus, Platform, SafeAreaView, View } from 'react-native';
import 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import checkVersion from 'react-native-store-version';
import { useDispatch, useSelector } from 'react-redux';
import DrawerContent from './DrawerContent';
import H1 from './components/H1';
import Icon from './components/Icon';
import ModalNewUpdate from './components/ModalNewUpdate';
import ModalTransferringData from './components/ModalTransferringData';
import SIButton from './components/SIButton';
import SISpinner from './components/SISpinner';
import SIText from './components/SIText';
import ContactsScreen from './screens/ContactsScreen';
import CreateNewShiftScreen from './screens/CreateNewShiftScreen';
import DevScreen from './screens/DevScreen';
import LogsScreen from './screens/LogsScreen';
import MeScreen from './screens/MeScreen';
import MyAlertsScreen from './screens/MyAlertsScreen';
import ShiftRequestForHolidaysScreen from './screens/RequestForHolidaysScreen';
import ShiftRequestForModificationsScreen from './screens/RequestForModificationsScreen';
import ScanQrCodeScreen from './screens/ScanQrCodeScreen';
import SettingsScreen from './screens/SettingsScreen';
import ShiftDetailsScreen from './screens/ShiftDetailsScreen';
import TakePictureScreen from './screens/TakePictureScreen';
import TomorrowScreen from './screens/TomorrowScreen';
import ToolQuantityScreen from './screens/ToolQuantityScreen';
import ToolsListScreen from './screens/ToolsListScreen';
import ViewPictureScreen from './screens/ViewPictureScreen';
import ForgotPasswordScreen from './screens/auth/forgot-password';
import LoginScreen from './screens/auth/login';
import AvailabilitiesScreen from './screens/availabilities';
import CalendarScreen from './screens/calendar';
import ContractsScreen from './screens/contracts';
import CreateNewContractScreen from './screens/contracts/CreateContract';
import DashboardScreen from './screens/dashboard';
import CreateNewDimonaScreen from './screens/dimona';
import DocumentsScreen from './screens/documents';
import FreeShiftsScreen from './screens/free-shifts';
import HomeScreen from './screens/home';
import CheckInScreen from './screens/home/CheckInScreen';
import ScheduleScreen from './screens/schedule';
import ShyftsMarketPage from './screens/shyfts-market';
import SignatureView from './screens/signature';
import SignScreen from './screens/signature/SignScreen';
import SwapShiftScreen from './screens/swap-shift';
import SwapShiftRequestsScreen from './screens/swap-shift/requests';
import { ActionType, InitialState } from './store';
import { checkLastClockingsWithCurrentClocking } from './store/actions/clockingActions';
import colors from './styles/colors';
import { IUserDetails } from './types/userDetails.model';
import { setAxiosHeaders } from './utils';

let Pusher: any = null;

if (Platform.OS !== 'web') {
  Pusher = require('pusher-js/react-native');
} else {
  Pusher = require('pusher-js');
}

const Drawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const Navigation: React.FC = () => {
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const authState = useSelector((store: InitialState) => store.auth);
  const offlineSate = useSelector((store: InitialState) => store.offline);
  const dataState = useSelector((store: InitialState) => store.data);
  const clockingState = useSelector((store: InitialState) => store.clocking);
  const [appState, setAppState] = useState<AppStateStatus>('active');
  const [mustUpdate, setMustUpdate] = useState<boolean>(false);
  const [dataAreLoading, setDataAreLoading] = useState<boolean>(false);
  const [dataError, setDataError] = useState<boolean>(false);
  const [retryToGetData, setRetryToGetData] = useState<number>(0);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [logged, setLogged] = useState<boolean>(false);
  const isOnline = !!offlineSate?.online;

  const navigationRef = React.useRef<NavigationContainerRef>(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      axios
        .get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/features`,
        )
        .then(({ data }) => {
          reduxDispatch({
            type: 'SET_FEATURES',
            payload: data,
          });
        })
        .catch((err) => {
          reduxDispatch({
            type: 'SET_FEATURES',
            payload: [],
          });
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  // AXIOS INTERCEPTORS AND AUTHORIZATION HEADER
  useEffect(() => {
    const responseInterceptor = Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response?.status === 401) {
          reduxDispatch({
            type: 'AUTH_SET_TOKEN',
            payload: null,
          });
          setLogged(false);
        }
        return Promise.reject(error);
      },
    );

    if (authState.accessToken) {
      setAxiosHeaders(authState.accessToken);
      setLogged(true);
    }

    return () => {
      Axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // INIT DATA
  useEffect(() => {
    if (!authState.accessToken) {
      setLogged(false);
    } else {
      setLogged(true);
    }

    if (offlineSate?.online) {
      if (authState.accessToken || retryToGetData) {
        reduxDispatch({
          type: 'CLOCKING_HARD_RESET',
        });
        getDatas();
      }
    }
  }, [authState.accessToken, retryToGetData]);

  const getDatas = async (loadData = true, forceReset = false) => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (loadData) {
      setDataAreLoading(true);
    }
    setDataError(false);

    if (offlineSate.busy || offlineSate.outbox?.length) {
      if (offlineSate.outbox[offlineSate.outbox.length]?.type !== 'REFRESH_DATA') {
        setTimeout(() => {
          setRetryToGetData(retryToGetData + 1);
        }, 2000);
      }
      setDataAreLoading(false);
      return;
    }
    const expoPushToken = await AsyncStorage.getItem('expo-token');
    const { status } = await Notifications.getPermissionsAsync();
    Axios.get(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/datas`,
      {
        params: {
          userMainId: authState.userDetails?.id,
          mobile: true,
          sections: true,
          tasks: true,
          skills: true,
          notifications: status,
          platform: dataState.platform,
          version: dataState.version,
          brand: dataState.brand,
          modelName: dataState.modelName,
          os: dataState.os,
        },
        headers: {
          'x-expo-token': expoPushToken,
        },
        cancelToken: cancelTokenSource.token,
      },
    )
      .then((response) => {
        const {
          departments,
          shifts,
          currentClockings,
          lastClockings,
          signaturesRequired,
          userDetails,
          clockinAccount,
          oncall,
          indicators = [],
          features,
          swapShiftsRequests,
        } = response.data;
        checkLastClockingsWithCurrentClocking(lastClockings || [], clockingState, isOnline, reduxDispatch);
        reduxDispatch({ type: 'SIGNATURE_SET_REQUIRED_SIGNATURES', payload: signaturesRequired });
        reduxDispatch({ type: 'AUTH_UPDATE_USER_DETAILS', payload: userDetails });
        reduxDispatch({
          type: 'DATA_SET_DATA',
          payload: {
            departments,
            shifts,
            currentClockings,
            clockinAccount,
            oncall,
            indicators,
            swapShiftsRequests,
            features,
            userDetails,
          },
        });
        if (loadData) {
          setDataAreLoading(false);
        }
        if (forceReset) {
          reduxDispatch({
            type: 'CLOCKING_SET_CURRENT_CLOCKING',
            payload: currentClockings[0] || null,
          });
        }
      })
      .catch((error) => {
        if (loadData) {
          setDataAreLoading(false);
        }
        if (!Axios.isCancel(error)) {
          setDataError(true);
        }
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  useEffect(() => {
    const listener = AppState.addEventListener('change', onAppStateChange);

    return () => {
      listener.remove();
    };
  }, []);

  const onAppStateChange = (newAppState: AppStateStatus) => {
    setAppState(newAppState);
  };

  useEffect(() => {
    if (Platform.OS !== 'web' && appState == 'active') {
      checkUpdates();
    }
    if (appState == 'active') {
      visit();
      // getDatas(false);
    }
  }, [appState]);

  const visit = async () => {
    await axios
      .post(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/visit`,
        {
          mobile: true,
          os: Platform.OS,
        },
      )
      .then(() => {})
      .catch(() => {});
  };

  const checkUpdates = async () => {
    try {
      if (Constants.manifest?.version || Constants?.manifest2?.extra?.expoClient?.version) {
        const check = await checkVersion({
          version: (Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version) as any,
          iosStoreURL:
            Constants.manifest?.extra?.APPSTORE_URL || Constants.manifest2?.extra?.expoClient?.extra?.APPSTORE_URL,
          androidStoreURL:
            Constants.manifest?.extra?.GOOGLE_PLAY_URL ||
            Constants.manifest2?.extra?.expoClient?.extra?.GOOGLE_PLAY_URL,
          country: authState.userDetails?.userLang ? authState.userDetails?.userLang.split('_')[1] : 'be',
        });

        if (check.result === 'new') {
          setMustUpdate(true);
        } else {
          setMustUpdate(false);
        }
      }
    } catch (err) {
      setMustUpdate(false);
    }
  };

  const onLogout = async () => {
    const deviceId = await AsyncStorage.getItem('device-id');
    Axios.post(
      `${
        Constants.manifest?.extra?.API_URL ||
        Constants.manifest?.extra?.API_URL ||
        Constants.manifest2?.extra?.expoClient?.extra?.API_URL
      }/v3/logout`,
      {
        deviceId,
      },
    );
    reduxDispatch({ type: RESET_STATE });
    reduxDispatch({
      type: 'RESET_STORE',
    });

    await AsyncStorage.removeItem('device-id');
  };

  if (!logged || !authState.accessToken) {
    return (
      <SafeAreaProvider>
        <NavigationContainer ref={navigationRef}>
          <Stack.Navigator>
            <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Forgot password" component={ForgotPasswordScreen} options={{ headerShown: false }} />
          </Stack.Navigator>
        </NavigationContainer>
      </SafeAreaProvider>
    );
  } else {
    if (dataAreLoading || !logged) {
      return <SISpinner />;
    }
    if (!dataState.lastUpdate && dataError) {
      return (
        <SafeAreaProvider>
          <SafeAreaView>
            <View style={{ padding: 25 }}>
              <H1>{t('GENERAL.ERROR_RETRIEVING_DATA')}</H1>
              <SIButton
                size="large"
                title={t('GENERAL.TRY_AGAIN')}
                onPress={() => setRetryToGetData(retryToGetData + 1)}
                style={{ marginTop: 25 }}
              />
              <SIButton
                size="large"
                title={t('AUTH.LOGOUT')}
                onPress={onLogout}
                backgroundColor={colors.red}
                style={{ marginTop: 25 }}
              />
            </View>
          </SafeAreaView>
        </SafeAreaProvider>
      );
    } else {
      if (authState.userDetails && authState.userDetails.forceLogout && Platform.OS !== 'web') {
        // forceLogout();
      }
      return (
        <NavigationContainer ref={navigationRef}>
          <MyPusherProvider
            accessToken={authState.accessToken}
            user={authState.userDetails}
            mustUpdate={mustUpdate}
            setMustUpdate={setMustUpdate}
            getDatas={getDatas}
          />
        </NavigationContainer>
      );
    }
  }
};

const MyPusherProvider: React.FC<{
  accessToken: string;
  user: IUserDetails | null;
  mustUpdate: boolean;
  setMustUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  getDatas: (loadData?: boolean) => (() => void) | undefined;
}> = ({ accessToken, user, mustUpdate, setMustUpdate, getDatas }) => {
  const { features } = useSelector((store: InitialState) => store.data);
  const staffUpdatePopupFeature = features && features.find((feature) => feature.key == 'staff_update_popup');
  const offlineSate = useSelector((store: InitialState) => store.offline);
  const isOnline = !!offlineSate.online;

  const hasSomeOfflineActions =
    offlineSate && offlineSate.outbox && !!offlineSate.outbox.length
      ? offlineSate.outbox.filter(
          (element: any) => element.meta.wasOnline !== undefined && element.meta.wasOnline == false,
        ).length > 0
      : false;

  return (
    <>
      <ModalTransferringData visible={isOnline && hasSomeOfflineActions} />
      {staffUpdatePopupFeature && <ModalNewUpdate user={user} visible={mustUpdate} setVisible={setMustUpdate} />}
      <AuthDrawerNavigator getDatas={getDatas} />
    </>
  );
};

const AuthDrawerNavigator: React.FC<{
  getDatas?: (loadData?: boolean, force?: boolean) => (() => void) | undefined;
}> = ({ getDatas }) => {
  const authState = useSelector((store: InitialState) => store.auth);
  const offlineSate = useSelector((store: InitialState) => store.offline);
  const dataState = useSelector((store: InitialState) => store.data);
  const reduxDispatch: Dispatch<any> = useDispatch();
  const isOnline = !!offlineSate?.online;

  const PUSHER_APP_KEY =
    Constants.manifest?.extra?.PUSHER_KEY || Constants.manifest2?.extra?.expoClient?.extra?.PUSHER_KEY;
  const PUSHER_CLUSTER =
    Constants.manifest?.extra?.PUSHER_CLUSTER || Constants.manifest2?.extra?.expoClient?.extra?.PUSHER_CLUSTER;

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_CLUSTER,
      forceTLS: true,
      authEndpoint: `${
        Constants.manifest?.extra?.API_URL.slice(0, -4) ||
        Constants.manifest2?.extra?.expoClient?.extra!.API_URL.slice(0, -4)
      }/broadcasting/auth`,
      auth: {
        headers: { Authorization: `Bearer ${authState.accessToken}` },
      },
    });
    const channel = pusher.subscribe(`private-App.User.${authState.userDetails?.id}`);
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', async (message: any) => {
      if (message.type === 'REFRESH_DATA' || (message.type == 'refresh' && message.resource == 'SWAP_SHIFTS')) {
        if (
          offlineSate.outbox?.length &&
          offlineSate.outbox?.[offlineSate.outbox.length] &&
          offlineSate.outbox?.[offlineSate.outbox.length].type !== 'REFRESH_DATA'
        ) {
          const expoPushToken = await AsyncStorage.getItem('expo-token');
          const { status } = await Notifications.getPermissionsAsync();
          reduxDispatch({
            type: 'REFRESH_DATA',
            meta: {
              wasOnline: isOnline,
              offline: {
                effect: {
                  url: `${
                    Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
                  }/v3/datas?userMainId=${
                    authState.userDetails?.id
                  }&mobile=true&sections=true&tasks=true&skills=true&notifications=${status}&platform=${
                    dataState.platform
                  }&version=${dataState.version}&brand=${dataState.brand}&modelName=${dataState.modelName}&os=${
                    dataState.os
                  }`,
                  method: 'GET',
                  headers: expoPushToken
                    ? {
                        'x-expo-token': expoPushToken,
                      }
                    : undefined,
                },
                commit: {
                  type: 'REFRESH_DATA_COMMIT',
                },
              },
            },
          });
        } else {
          if (getDatas) {
            getDatas(false, true);
          }
        }
      }
    });
  }, []);

  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContent={(props) => <DrawerContent {...props} itemStyle={{ backgroundColor: colors.red }} />}
    >
      <Drawer.Screen
        name="App"
        component={AppStackScreen}
        options={{ headerLeft: () => null, gestureEnabled: true, headerShown: false }}
      />
      <Drawer.Screen
        name="ScanQrCode"
        component={ScanQrCodeScreen}
        options={{ headerLeft: () => null, gestureEnabled: false, unmountOnBlur: true }}
      />
      <Drawer.Screen
        name="Signature"
        component={SignStackScreen}
        options={{ headerLeft: () => null, gestureEnabled: false }}
      />
    </Drawer.Navigator>
  );
};

export default Navigation;

function AppStackScreen() {
  const navigation = useNavigation();
  const signaturesState = useSelector((store: InitialState) => store.signatures);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const authState = useSelector((store: InitialState) => store.auth);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineSate = useSelector((store: InitialState) => store.offline);
  const {
    clockinAccount,
    oncall,
    departments,
    access_shifts_departments,
    access_swap_shifts_departments,
    shyfts_market_departments,
  } = useSelector((store: InitialState) => store.data);
  const ADMIN = ['SUPER-ADMIN', 'ADMIN'].includes(authState.userDetails?.role || '');
  const isOnline = !!offlineSate?.online;

  useEffect(() => {
    getUnsignedDocuments();
  }, []);

  const getUnsignedDocuments = () => {
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4
        }/documents/unsigned`,
      )
      .then(({ data }) => {
        if (!!data.data.length) {
          navigation.navigate('Signature', {
            screen: 'SignatureContract',
            params: {
              document: data.data[0],
              allDocuments: data.data,
            },
          });
        } else {
          navigation.navigate('Home');
        }
      })
      .catch((error) => {
        navigation.navigate('Home');
      });
  };

  return (
    <Stack.Navigator>
      <Stack.Screen name="Tab" component={TabScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ClockingCheckIn" component={CheckInScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ShiftDetails" component={ShiftDetailsScreen} options={{ headerShown: false }} />
      <Stack.Screen
        name="ShiftRequestForModifications"
        component={ShiftRequestForModificationsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="TakePicture"
        component={TakePictureScreen}
        options={{ headerBackTitle: t('GENERAL.BACK'), title: t('CLOCKING.TAKE_PICTURE') }}
      />
      <Stack.Screen name="ViewPicture" component={ViewPictureScreen} />
      <Stack.Screen
        name="ToolsList"
        component={ToolsListScreen}
        options={{ headerBackTitle: t('GENERAL.BACK'), title: t('CLOCKING.TOOLS') }}
      />
      <Stack.Screen
        name="ToolQuantity"
        component={ToolQuantityScreen}
        options={{ headerBackTitle: t('GENERAL.BACK'), title: t('CLOCKING.TOOLS_QUANTITY') }}
      />
      {isOnline && !clockinAccount && (
        <Stack.Screen name="CreateNewShift" component={CreateNewShiftScreen} options={{ headerShown: false }} />
      )}
      {isOnline && (
        <Stack.Screen name="CreateNewDimona" component={CreateNewDimonaScreen} options={{ headerShown: false }} />
      )}
      {isOnline && (
        <Stack.Screen name="CreateNewContract" component={CreateNewContractScreen} options={{ headerShown: false }} />
      )}
      {oncall && (
        <Stack.Screen
          name="MyAlerts"
          component={MyAlertsScreen}
          options={{ headerBackTitle: t('GENERAL.BACK'), title: t('GENERAL.MY_ALERTS') }}
        />
      )}
      <Stack.Screen name="Contacts" component={ContactsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Contracts" component={ContractsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Documents" component={DocumentsScreen} options={{ headerShown: false }} />
      {ADMIN && <Stack.Screen name="Logs" component={LogsScreen} options={{ headerShown: false }} />}
      {access_shifts_departments && access_shifts_departments.length > 0 && (
        <Stack.Screen name="Tomorrow" component={TomorrowScreen} options={{ headerShown: false }} />
      )}
      {isOnline && (
        <Stack.Screen name="Availabilities" component={AvailabilitiesScreen} options={{ headerShown: false }} />
      )}
      <Stack.Screen name="Schedule" component={ScheduleScreen} options={{ headerShown: false }} />
      <Stack.Screen name="FreeShifts" component={FreeShiftsScreen} options={{ headerShown: false }} />
      {isOnline && <Stack.Screen name="Me" component={MeScreen} options={{ headerShown: false }} />}
      {isOnline && <Stack.Screen name="Settings" component={SettingsScreen} options={{ headerShown: false }} />}
      {/* {!!departments.filter((x) => x.interventionSheets).length && (
        <Stack.Screen name="Intervention" component={InterventionStack} options={{ headerShown: false }} />
      )} */}
      {isOnline && access_swap_shifts_departments && access_swap_shifts_departments.length ? (
        <>
          <Stack.Screen name="SwapShift" component={SwapShiftScreen} options={{ headerShown: false }} />
          <Stack.Screen name="SwapShiftRequest" component={SwapShiftRequestsScreen} options={{ headerShown: false }} />
        </>
      ) : null}
      {isOnline && shyfts_market_departments && shyfts_market_departments.length > 0 ? (
        <>
          <Stack.Screen name="ShyftsMarket" component={ShyftsMarketPage} options={{ headerShown: false }} />
        </>
      ) : null}
      <Stack.Screen name="Dev" component={DevScreen} options={{ headerBackTitle: t('GENERAL.BACK'), title: 'DEV' }} />
    </Stack.Navigator>
  );
}

function SignStackScreen() {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignatureContract"
        component={SignatureView}
        options={{ headerLeft: () => null, gestureEnabled: false, headerShown: false }}
      />
      <Stack.Screen
        name="SignatureSign"
        component={SignScreen}
        options={{ headerBackTitle: t('GENERAL.CONTRACT'), headerTitle: t('GENERAL.SIGNATURE'), gestureEnabled: false }}
      />
    </Stack.Navigator>
  );
}

const EmptyComponent = () => null;

function TabScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const authState = useSelector((store: InitialState) => store.auth);
  const dataState = useSelector((store: InitialState) => store.data);
  const offlineState = useSelector((store: InitialState) => store.offline);
  const {
    clockinAccount,
    departments,
    access_shifts_departments,
    access_clocking_departments,
    access_vacation_requests_departments,
    admin_planning_hr_departments,
  } = useSelector((store: InitialState) => store.data);
  const isOnline = !!offlineState?.online;

  const screenOptions = (props: {
    route: RouteProp<Record<string, any | undefined>, string>;
    navigation: any;
  }): BottomTabNavigationOptions => {
    const { route } = props;
    let iconName: string;
    let iconSize = 28;
    switch (route.name) {
      case 'Home': {
        iconName = 'home';
        iconSize = 24;
        break;
      }
      case 'More': {
        iconName = 'navigation-more';
        iconSize = 20;
        break;
      }
      case 'Calendar': {
        iconName = 'calendar';
        iconSize = 20;
        break;
      }
      case 'Dashboard': {
        iconName = 'planning-today';
        iconSize = 20;
        break;
      }
      case 'ShiftRequestForHolidays': {
        iconName = 'dayoff';
        iconSize = 20;
        break;
      }
      default: {
        iconName = 'calendar';
      }
    }
    return {
      // eslint-disable-next-line react/display-name
      tabBarIcon: ({ color }) => {
        if (iconName == 'navigation-more') {
          return (
            <View>
              <Icon name={iconName} size={iconSize} color={color}></Icon>
              {dataState.swap_shift_requests > 0 && (
                <View
                  style={{
                    position: 'absolute',
                    backgroundColor: colors.red,
                    paddingHorizontal: 5,
                    borderRadius: 50,
                    top: -8,
                    right: -15,
                  }}
                >
                  <SIText style={{ color: '#fff', fontSize: 10 }}>
                    {dataState.swap_shift_requests <= 9 ? dataState.swap_shift_requests : '9+'}
                  </SIText>
                </View>
              )}
            </View>
          );
        }
        return <Icon name={iconName} size={iconSize} color={color}></Icon>;
      },
    };
  };

  return (
    <Tab.Navigator
      screenOptions={screenOptions}
      tabBarOptions={{ activeTintColor: colors.green, inactiveTintColor: colors.grey, labelPosition: 'below-icon' }}
    >
      <Tab.Screen name="Home" component={HomeScreen} options={{ tabBarLabel: t('GENERAL.HOME') }} />
      {!clockinAccount && (
        <Tab.Screen name="Calendar" component={CalendarScreen} options={{ tabBarLabel: t('GENERAL.CALENDAR') }} />
      )}
      {isOnline &&
        admin_planning_hr_departments &&
        admin_planning_hr_departments.length > 0 &&
        ((access_shifts_departments && access_shifts_departments.length > 0) ||
          (access_clocking_departments && access_clocking_departments.length > 0)) && (
          <Tab.Screen
            name="Dashboard"
            component={DashboardScreen}
            options={{ tabBarLabel: t('GENERAL.DASHBOARD_TAB') }}
          />
        )}
      {isOnline && access_vacation_requests_departments && access_vacation_requests_departments.length > 0 && (
        <Tab.Screen
          name="ShiftRequestForHolidays"
          component={ShiftRequestForHolidaysScreen}
          options={{ tabBarLabel: t('GENERAL.DAYOFF') }}
        />
      )}
      {isOnline && (
        <Tab.Screen
          name="More"
          component={EmptyComponent}
          options={{ tabBarLabel: t('GENERAL.MORE') }}
          listeners={{
            tabPress: (e) => {
              e.preventDefault();
              navigation.dispatch(DrawerActions.openDrawer());
            },
          }}
        />
      )}
    </Tab.Navigator>
  );
}
