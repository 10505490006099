import { useFocusEffect } from '@react-navigation/core';
import axios from 'axios';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Select from '../../components/Select';
import ShiftCards from '../../components/ShiftCards';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
import { IDepartment } from '../../types/department.model';
import { IShift } from '../../types/shift.model';
import { IUser } from '../../types/user.model';

LocaleConfig.locales['fr'] = {
  monthNames: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
  monthNamesShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
  dayNames: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  dayNamesShort: 'Dim._Lun._Mar._Mer._Jeu._Ven._Sam.'.split('_'),
};

LocaleConfig.locales['nl'] = {
  monthNames: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  monthNamesShort: 'Jan._Feb._Mrt._Apr._Mei_Jun._Jul._Aug._Sep._Okt._Nov._Dec.'.split('_'),
  dayNames: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  dayNamesShort: 'Zo._Ma._Di._Wo._Do._Vr._Za.'.split('_'),
};

LocaleConfig.locales['de'] = {
  monthNames: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  monthNamesShort: 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
  dayNames: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  dayNamesShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
};

switch (Localization.locale.substring(0, 2)) {
  case 'fr':
    LocaleConfig.defaultLocale = 'fr';
    break;
  case 'nl':
    LocaleConfig.defaultLocale = 'nl';
    break;
  case 'de':
    LocaleConfig.defaultLocale = 'de';
    break;
  default:
    LocaleConfig.defaultLocale = '';
    break;
}

moment.tz.setDefault('Atlantic/Reykjavik');

const ScheduleScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(moment().format('YYYY-MM-DD'));
  const [selectedMonth, setSelectedMonth] = useState<string | null>(moment().startOf('month').format('YYYY-MM'));
  const [shifts, setShifts] = useState<IShift[]>([]);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { access_global_schedule_departments, admin_planning_hr_departments } = useSelector(
    (store: InitialState) => store.data,
  );
  const { setValue, errors, control, watch } = useForm();
  const watchDepartmentId = watch('departmentId', null);
  const [timestamp, setTimestamp] = useState<number>(0);

  const merged_departments = Array.from(
    new Set([...access_global_schedule_departments, ...admin_planning_hr_departments]),
  );

  const onDayPress = (day: any) => {
    const { dateString } = day;
    if (selectedDate === dateString) {
      setSelectedDate(null);
    } else {
      setSelectedDate(dateString);
    }
  };

  const markedDates = Object.entries(shifts).reduce((obj: any, item) => ((obj[item[0]] = { marked: true }), obj), {});

  useFocusEffect(
    useCallback(() => {
      setTimestamp(moment().unix());
      if (!watchDepartmentId && merged_departments.length === 1) {
        setTimeout(() => {
          setValue('departmentId', merged_departments[0].id);
        }, 0);
      }
    }, []),
  );

  useEffect(() => {
    if (watchDepartmentId) {
      setIsLoading(true);
      const cancelTokenSource = axios.CancelToken.source();

      axios
        .get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/shifts`,
          {
            params: {
              departmentId: watchDepartmentId,
              startDate: selectedDate ? moment(selectedDate).startOf('day').unix() : moment().startOf('month').unix(),
              endDate: selectedDate ? moment(selectedDate).endOf('day').unix() : moment().endOf('month').unix(),
              approved: true,
            },
            cancelToken: cancelTokenSource.token,
          },
        )
        .then((response) => {
          const { departments, users, shifts }: { departments: IDepartment[]; users: IUser[]; shifts: IShift[] } =
            response.data;
          const departmentsMap = new Map<string, IDepartment>();
          const usersMap = new Map<string, IUser>();

          for (let i = 0; i < departments.length; i++) {
            const department = departments[i];
            department.shifts = [];
            departmentsMap.set(department.id!, department);
          }

          for (let i = 0; i < users.length; i++) {
            const user = users[i];
            usersMap.set(user.recordId!, user);
          }

          for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const department = departmentsMap.get(shift.departmentId!);
            const userRecordId = shift.userRecordId;

            if (userRecordId) {
              const user = usersMap.get(shift.userRecordId!);
              shift.user = user;
              department?.shifts?.push(shift);
            }
          }
          setShifts(shifts.sort((a, b) => a.start! - b.start!));
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!axios.isCancel(error)) {
            if (Platform.OS === 'web') {
              alert(`${t('GENERAL.ERROR')}. ${t('GENERAL.ERROR_RETRIEVING_DATA')}`);
            } else {
              Alert.alert(t('GENERAL.ERROR'), t('GENERAL.ERROR_RETRIEVING_DATA'));
            }
          }
          console.log(error);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [watchDepartmentId, selectedMonth, timestamp, selectedDate]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back title={t('GENERAL.SCHEDULE')} />
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Select
            label={t('GENERAL.ACCOUNT')}
            control={control}
            rules={{ required: true }}
            errors={errors}
            defaultValue={null}
            name="departmentId"
            items={merged_departments.map((department) => ({
              label: department.company!,
              value: department.id!,
            }))}
            style={{
              backgroundColor: '#FFF',
              display: merged_departments.length == 1 ? 'none' : 'flex',
            }}
          />
          {watchDepartmentId && (
            <React.Fragment>
              <Calendar
                current={moment().format('YYYY-MM-DD')}
                onDayPress={onDayPress}
                markedDates={selectedDate ? { ...markedDates, [selectedDate]: { selected: true } } : markedDates}
                onMonthChange={(month) => setSelectedMonth(month.dateString.substring(0, 7))}
                style={styles.calendar}
                displayLoadingIndicator={isLoading}
                firstDay={1}
                theme={{
                  monthTextColor: 'black',
                  textMonthFontFamily: 'Poppins_500Medium',
                  textDayFontFamily: 'Poppins_500Medium',
                  textDayHeaderFontFamily: 'Poppins_500Medium',
                  dotColor: colors.grey,
                  todayTextColor: colors.green,
                  arrowColor: colors.green,
                  selectedDayBackgroundColor: colors.green,
                }}
              />
              {!isLoading && (
                <ShiftCards
                  title={selectedDate ? `${moment(selectedDate).format('LL')}` : undefined}
                  // eslint-disable-next-line prefer-spread
                  shifts={selectedDate ? shifts || [] : ([] as IShift[]).concat.apply([], Object.values(shifts))}
                  publicMode={true}
                  dates={selectedDate == null}
                />
              )}
            </React.Fragment>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 25,
    display: 'flex',
    flex: 1,
    backgroundColor: colors.blueExtraLight,
  },
  content: {
    paddingBottom: 50,
  },
  calendar: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 0,
    paddingBottom: 20,
  },
  textAreaContainer: {
    marginTop: 5,
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.greyLight,
    borderRadius: 3,
  },
  textArea: {
    height: 100,
  },
});

export default ScheduleScreen;
