import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Icon from '../../components/Icon';
import SISectionList from '../../components/SISectionList';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
moment.tz.setDefault('Atlantic/Reykjavik');

type V4Document = {
  id: string;
  departmentId: string;
  file: string;
  signedAt: string | null;
  sentAt: string;
  document: {
    title: string;
  };
};

interface ItemProps {
  document: V4Document;
}

const Item: React.FC<ItemProps> = ({ document }) => {
  const { sentAt, file, document: docDoc } = document;

  return (
    <TouchableOpacity
      style={styles.item}
      onPress={async () => {
        if (file) {
          await WebBrowser.openBrowserAsync(file);
        }
      }}
    >
      <View style={styles.textContainer}>
        <SIText style={styles.title}>{docDoc.title}</SIText>
        <SIText>{`${moment(sentAt).format('L')}`}</SIText>
      </View>
      <Icon name="eye" color={colors.green} size={16} />
    </TouchableOpacity>
  );
};

const DocumentsScreen: React.FC = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<V4Document[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { departments } = useSelector((store: InitialState) => store.data);

  const refreshData = () => {
    if (!isLoading) {
      setIsLoading(true);
      getDocuments();
    }
  };
  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  const getDocuments = () => {
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4
        }/documents/general`,
      )
      .then(({ data }) => {
        setDocuments(data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SafeAreaView style={styles.container}>
      <Back title={t('GENERAL.MY_DOCUMENTS')} />
      <SISectionList
        refreshControl={
          <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
        }
        sections={[...new Set(documents.map((document) => document.departmentId))].map((departmentId) => {
          const department = departments.find((department) => departmentId == department.id);
          const departmentDocuments = documents.filter((document) => document.departmentId == departmentId);
          return {
            title: department?.company || 'unknown',
            data: departmentDocuments || [],
          };
        })}
        renderItem={({ item }) => <Item document={item} />}
        keyExtractor={(item, index) => `${index}`}
        style={{ padding: 25 }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blueExtraLight,
    flex: 1,
  },
  item: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  textContainer: {
    flex: 1, // Takes the available space
    marginRight: 10, // Adds some space before the icon
  },
  title: {
    flexWrap: 'wrap', // Wraps text to next line
    fontSize: 16, // Adjust if necessary
    lineHeight: 20, // Spacing between lines
    maxWidth: '100%', // Ensures it doesn't overflow
  },
});

export default DocumentsScreen;
