import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import * as Device from 'expo-device';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler, Dimensions, Image, Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import SIButton from '../../components/SIButton';
import { V4Document } from '../../types/document.model';

type NavigationParams = {
  SignatureView: {
    document: V4Document;
    allDocuments: V4Document[];
  };
};

type Props = StackScreenProps<NavigationParams, 'SignatureView'>;

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const SignatureView: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const [deviceType, setDeviceType] = useState(Device.DeviceType.DESKTOP);
  const [document, setDocument] = useState<V4Document>();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const getDeviceType = async () => {
      const deviceType = await Device.getDeviceTypeAsync();
      setDeviceType(deviceType);
    };

    getDeviceType();
  }, []);

  useEffect(() => {
    if (!route.params) {
      navigation.navigate('Home');
    } else {
      setDocument(route.params.document);
    }
  }, [route.params]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => true;
      const listener = BackHandler.addEventListener('hardwareBackPress', onBackPress);
      return () => {
        listener.remove();
      };
    }, []),
  );

  const webDesktop =
    Platform.OS == 'web' && (deviceType == Device.DeviceType.DESKTOP || deviceType == Device.DeviceType.UNKNOWN);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'white',
      padding: 10,
    },
    scrollViewContent: {
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center', // Ensures horizontal centering
    },
    imageContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: 10,
      width: webDesktop ? 380 : screenWidth - 20,
      height: webDesktop ? 650 : screenHeight - 150,
    },
    image: {
      width: webDesktop ? 380 : screenWidth - 20,
      height: webDesktop ? 650 : screenHeight - 150,
    },
    button: {
      marginTop: 10,
    },
  });

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        {document?.document?.images.map((image, index) => (
          <View key={index} style={styles.imageContainer}>
            <Image
              source={{
                uri: image,
              }}
              style={styles.image}
              resizeMode="center"
            />
          </View>
        ))}
      </ScrollView>
      <SIButton
        style={styles.button}
        title={t('GENERAL.SIGN')}
        size="large"
        onPress={() => {
          navigation.navigate('SignatureSign', { selectedDocument: document, allDocuments: route.params.allDocuments });
        }}
      />
    </SafeAreaView>
  );
};

export default SignatureView;
