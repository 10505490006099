import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import Back from '../../components/Back';
import Icon from '../../components/Icon';
import SISectionList from '../../components/SISectionList';
import SIText from '../../components/SIText';
import { InitialState } from '../../store';
import colors from '../../styles/colors';
moment.tz.setDefault('Atlantic/Reykjavik');

type V4Contract = {
  id: string;
  departmentId: string;
  start: string;
  end: string | null;
  pdfUrl: string;
};

interface ItemProps {
  document: V4Contract;
}

const Item: React.FC<ItemProps> = ({ document }) => {
  const { start, end, pdfUrl } = document;
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={async () => {
        if (pdfUrl) {
          await WebBrowser.openBrowserAsync(pdfUrl);
        }
      }}
    >
      <SIText>{`${moment(start).format('L')} - ${end ? moment(end).format('L') : '__/__/____'}`}</SIText>
      <Icon name="eye" color={colors.green} size={16} />
    </TouchableOpacity>
  );
};

const DocumentsScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<V4Contract[]>([]);
  const { departments } = useSelector((store: InitialState) => store.data);

  const refreshData = () => {
    if (!isLoading) {
      setIsLoading(true);
      getContracts();
    }
  };

  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  const getContracts = () => {
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4
        }/documents/contracts`,
      )
      .then(({ data }) => {
        setContracts(data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SafeAreaView style={styles.container}>
      <Back title={t('GENERAL.MY_CONTRACTS')} />
      <SISectionList
        refreshControl={
          <RefreshControl onRefresh={refreshData} refreshing={isLoading} style={{ flexDirection: 'column' }} />
        }
        sections={[...new Set(contracts.map((contract) => contract.departmentId))].map((departmentId) => {
          const department = departments.find((department) => departmentId == department.id);
          const departmentContracts = contracts.filter((contract) => contract.departmentId == departmentId);
          return {
            title: department?.company || 'unknown',
            data: departmentContracts || [],
          };
        })}
        renderItem={({ item }) => <Item document={item} />}
        keyExtractor={(item, index) => `${index}`}
        style={{ padding: 25 }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blueExtraLight,
    flex: 1,
  },
  item: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
});

export default DocumentsScreen;
