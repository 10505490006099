import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { default as Axios, default as axios } from 'axios';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as ScreenOrientation from 'expo-screen-orientation';
import React, { Dispatch, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Dimensions, PanResponder, Platform, StyleSheet, View } from 'react-native';
import Svg, { Polyline } from 'react-native-svg';
import { captureRef } from 'react-native-view-shot';
import { useDispatch, useSelector } from 'react-redux';
import H1 from '../../components/H1';
import SIButton from '../../components/SIButton';
import { ActionType, InitialState } from '../../store';
import colors from '../../styles/colors';
import { V4Document } from '../../types/document.model';

interface Point {
  x: number;
  y: number;
}

const GesturePath = forwardRef((props: any, ref: any) => {
  const { paths, color, layout } = props;
  const { width } = Dimensions.get('window');
  return (
    <Svg ref={ref} height="100%" width="100%" viewBox={`0 0 ${layout.width} ${layout.height}`} id="svg_element">
      {paths.map((path: Point[], index: number) => {
        const points = path.map((p) => `${p.x},${p.y}`).join(' ');
        return <Polyline key={`path_${index}`} points={points} fill="none" stroke={color} strokeWidth="2" />;
      })}
    </Svg>
  );
});
GesturePath.displayName = 'GesturePathComponent';

const GestureRecorder = forwardRef((props: any, ref: any) => {
  const pathRef = useRef([[]]);
  const { onPathChanged } = props;

  useImperativeHandle(ref, () => ({
    clearSignature() {
      pathRef.current = [[]];
    },
  }));

  const panResponder = useRef(
    PanResponder.create({
      // Next line is needed to work on android
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pathRef.current.push([]);
      },
      onPanResponderMove: (event: any) => {
        const length = pathRef.current.length;
        pathRef.current[length - 1].push({
          x: event.nativeEvent.locationX,
          y: event.nativeEvent.locationY,
        });
        // Uncomment the next line to draw the path as the user is performing the touch. (A new array must be created so setState recognises the change and re-renders the App)
        onPathChanged([...pathRef.current]);
      },
      onPanResponderRelease: () => {
        onPathChanged([...pathRef.current]);
      },
    }),
  ).current;

  return (
    <View
      style={[StyleSheet.absoluteFill, { backgroundColor: 'rgba(255,255,255,0.1)' }]}
      {...panResponder.panHandlers}
    />
  );
});
GestureRecorder.displayName = 'GestureRecorderComponent';

type NavigationParams = {
  SignScreen: {
    selectedDocument: V4Document;
    allDocuments: V4Document[];
  };
};

type Props = StackScreenProps<NavigationParams, 'SignScreen'>;

const SignScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const [paths, setPaths] = useState<Point[][]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const gestureRecorder = useRef(null);
  const svgRef = useRef(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [layout, setLayout] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { departments } = useSelector((store: InitialState) => store.data);

  useEffect(() => {
    if (!route.params) {
      navigation.goBack();
    }
    if (Platform.OS !== 'web') {
      changeScreenOrientation();
    }
  }, [route.params]);

  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS !== 'web') {
        changeScreenOrientation();
      }

      return async () => {
        if (Platform.OS !== 'web') {
          await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        }
      };
    }, []),
  );

  async function changeScreenOrientation() {
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
  }

  const onPathChanged = (paths: Point[][]) => {
    setPaths(paths);
  };

  const onClearSignature = () => {
    if (gestureRecorder.current) {
      (gestureRecorder.current as any).clearSignature();
    }
    setPaths([]);
  };

  const onSaveSignature = async () => {
    setIsSaving(true);

    try {
      const { selectedDocument } = route.params;

      // Step 1: Capture the signature image
      let signatureBlob: Blob | null = null;
      const filename = `signature-${selectedDocument.document.id}-${Date.now()}.png`;

      if (Platform.OS === 'web') {
        const svgElement = document.getElementById('svg_element');
        if (svgElement) {
          const image = new Image();
          const xml = new XMLSerializer().serializeToString(svgElement);
          image.src = `data:image/svg+xml;utf8,${xml}`;

          await new Promise<void>((resolve, reject) => {
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const { width, height } = (svgElement as any).getBBox();
              canvas.width = width;
              canvas.height = height;

              const context = canvas.getContext('2d')!;
              context.drawImage(image, 0, 0, width, height);
              canvas.toBlob((blob) => {
                console.log(blob);
                if (blob) signatureBlob = blob;
                resolve();
              }, 'image/png');
            };
            image.onerror = reject;
          });
        }
      } else {
        const uri = await captureRef(svgRef, {
          result: 'tmpfile',
          quality: 1,
          format: 'png',
        });
        const response = await fetch(Platform.OS === 'android' ? uri : uri.replace('file://', ''));
        signatureBlob = await response.blob();
      }

      if (!signatureBlob) throw new Error('Failed to capture signature image.');

      // Step 2: Get signed S3 URL from backend
      const signedUrlResponse = await Axios.post(
        `${(Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4).replace(
          '/staff',
          '',
        )}/signed-storage-urls?departmentId=${selectedDocument.departmentId}`,
        {
          urls: [
            {
              filename,
              filetype: 'image/png',
            },
          ],
        },
      );

      // Step 3: Upload the file to S3

      const { data: signedUrls } = signedUrlResponse.data;

      const uploadPromises = signedUrls.map((signedUrl: any) => {
        return fetch(signedUrl.url, {
          body: signatureBlob,
          method: 'PUT',
          headers: {
            ...signedUrl.headers,
            'Content-Type': 'image/png',
          },
        });
      });

      await Promise.all(uploadPromises);

      const activeDepartment = departments.find((department) => department.id == selectedDocument.departmentId);
      const groupDocumentSignatures = activeDepartment ? activeDepartment.params?.group_document_signatures : true;

      // Step 4: Notify backend with S3 file location
      await Axios.post(
        `${
          Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4
        }/user-document-sign`,
        {
          file: filename,
          documents: [
            {
              type: selectedDocument.type,
              ids: groupDocumentSignatures
                ? route.params.allDocuments
                    .filter((document) => document.type == selectedDocument.type)
                    .map((document) => document.document.id)
                : [selectedDocument.document.id],
              departmentId: selectedDocument.departmentId,
            },
          ],
        },
      );

      // Notify success
      if (Platform.OS !== 'web') {
        Notifications.scheduleNotificationAsync({
          content: {
            title: t('SIGNATURE.SIGNATURE_SUCCESS_MESSAGE'),
          },
          trigger: {
            seconds: 1,
          },
        });
      }

      setIsSaving(false);

      navigation.navigate('Home');
      getUnsignedDocuments();
    } catch (error) {
      console.log(error);
      setIsSaving(false);

      const errorMessage = t('GENERAL.ERROR') + '. ' + t('SIGNATURE.SIGNATURE_FAILURE_MESSAGE');
      if (Platform.OS === 'web') {
        alert(errorMessage);
      } else {
        Alert.alert(t('GENERAL.ERROR'), errorMessage);
      }
    }
  };

  const getUnsignedDocuments = () => {
    axios
      .get(
        `${
          Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4
        }/documents/unsigned`,
      )
      .then(({ data }) => {
        if (!!data.data.length) {
          navigation.navigate('Signature', {
            screen: 'SignatureContract',
            params: {
              document: data.data[0],
            },
          });
        } else {
          navigation.navigate('Home');
        }
      })
      .catch((error) => {
        navigation.navigate('Home');
      });
  };

  return (
    <View style={styles.safeArea}>
      {/* <ScrollView style={styles.container} ref={scrollView}> */}
      <View
        style={styles.signatureContainer}
        onLayout={(e) => {
          setLayout({
            height: e.nativeEvent.layout.height,
            width: e.nativeEvent.layout.width,
          });
        }}
      >
        <GesturePath paths={paths} color="blue" ref={svgRef} layout={layout} />
        <GestureRecorder onPathChanged={onPathChanged} ref={gestureRecorder} />
      </View>
      {!paths?.length && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 100,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          pointerEvents="none"
        >
          <H1 style={{ color: colors.grey }}>{t('SIGNATURE.SIGN_HERE')}</H1>
        </View>
      )}

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 50 }}>
        <SIButton
          backgroundColor={colors.greyLight}
          color={colors.grey}
          title={t('GENERAL.ERASE')}
          width="auto"
          onPress={onClearSignature}
          style={{ margin: 10 }}
        />
        {!!paths?.length && (
          <SIButton
            style={{ margin: 10 }}
            title={t('GENERAL.SIGN')}
            width="auto"
            onPress={onSaveSignature}
            loading={isSaving}
          />
        )}
      </View>

      {/* </ScrollView> */}
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: 'white',
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'yellow',
  },
  signatureContainer: {
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.greyLight,
    backgroundColor: '#EFEFEF',
    marginBottom: 10,
  },
});

export default SignScreen;

const uploadFileToSignedUrl = async (payload: any[], files: File[]) => {
  const { data } = await axios.post(
    `${(Constants.manifest?.extra?.API_URL_V4 || Constants.manifest2?.extra?.expoClient?.extra?.API_URL_V4).replace(
      '/staff',
      '',
    )}/signed-storage-urls`,
  );

  const uploadPromises = files.map((file, index) => {
    const url = data[index];
    return fetch(url.url, {
      body: file,
      method: 'PUT',
      headers: {
        ...url.headers,
        'Content-Type': file.type,
      },
    });
  });

  await Promise.all(uploadPromises);
};
